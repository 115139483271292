import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import {
  Box,
  Button,
  Divider,
  Modal,
  Notification,
  Radio,
  Table,
  Typography,
} from "components";
import { PERMISSIONS } from "constants/enums/permissions";
import {
  compose,
  withAuthorize,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";

import { ReactComponent as CheckedRadioBoxIcon } from "assets/icon/checked_radio_box_icon.svg";
import { ReactComponent as UncheckRadioBoxIcon } from "assets/icon/uncheck_radio_box_icon.svg";
import Authorize from "components/Authorize";
import { MaterialIcon } from "components/common/MaterialIcon";
import { TFunction } from "i18next";
import { map, orderBy } from "lodash";
import { useEffect } from "react";
import enrollmentStore from "stores/enrollmentStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql } from "utils/helper";
import { InsuranceSelectorTable } from "./InsuranceSelectorTable";

const AddButton = styled(Button)`
  min-width: 151px;
`;

interface InsurancePackageListProps {
  label: string;
  isAddModalOpen: boolean;
  masterInsurancePackageOptions: any[];
  handleClickAddPackages: () => void;
  handleCloseAddModal: () => void;
  handleConfirmSelection: (values: any[]) => void;
  t: TFunction;
  tableData: [];
  columns: GridColDef[];
  viewOnly: boolean;
}

export const InsurancePackageListComponent = ({
  label,
  isAddModalOpen,
  masterInsurancePackageOptions,
  handleClickAddPackages,
  handleConfirmSelection,
  handleCloseAddModal,
  t,
  ...props
}: InsurancePackageListProps) => (
  <Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="body2">
        {t(".insuranceTypeHeader", { type: label })}
      </Typography>
      <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
        {!props.viewOnly && (
          <AddButton onClick={handleClickAddPackages} variant="outlined">
            <MaterialIcon name="Add" mr={4} />
            {t(".add")}
          </AddButton>
        )}
      </Authorize>
    </Box>

    {props.tableData.length > 0 ? (
      <Box mt={4}>
        <Table
          className="EnrollmentInsuranceListTable"
          columns={props.columns}
          rows={props.tableData}
          density="compact"
          autoHeight
          rowsPerPageOptions={[5, 10]}
          style={{ minHeight: "40px" }}
          hideFooterPagination
          hideFooterRowCount
          disableSelectionOnClick
        />
      </Box>
    ) : (
      <Divider my={4} />
    )}
    <InsuranceSelectorTable
      isShow={isAddModalOpen}
      packages={masterInsurancePackageOptions}
      onSelectionChange={handleConfirmSelection}
      onCancel={handleCloseAddModal}
    />
  </Box>
);

const API = {
  FETCH_INSURANCE_PACKAGES: gql`
    query FETCH_INSURANCE_PACKAGES($id: String!) {
      insurancePackages(id: $id) {
        id
        nameTh
        nameEn
        remarkTh
        remarkEn
        createdAt
        packagePricingType
        insurancePackagesInsurancePlans {
          id
          insurancePlanId
          packageId
          insurance {
            id
            nameTh
            nameEn
            remarkTh
            remarkEn
            premium
            description
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.enrollment.attendeeGrouping.InsuranceList",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useParams,
      useQuery,
      useState,
      useDataTranslation,
    } = hooks;
    const {
      label,
      t,
      viewOnly,
      masterBenefitInsurancePackages = [],
      hasPermission,
      handleChangeMasterBenefitInsurancePackages,
    } = props;

    const { id } = useParams();
    const { data: insurancePackagesResponse } = useQuery(
      API.FETCH_INSURANCE_PACKAGES,
      {
        variables: { id },
        fetchPolicy: "network-only",
      }
    );
    const insurancePackagesTranslated = useDataTranslation(
      insurancePackagesResponse?.insurancePackages
    );
    const masterInsurancePackages = useMemo(
      () => insurancePackagesTranslated || [],
      [insurancePackagesTranslated]
    );

    const [selectedInsurancePackages, setSelectedInsurancePackages] = useState(
      masterBenefitInsurancePackages
    );
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    const changeMasterBenefitInsurancePackages = useCallback(
      (selectedInsurances: any[]) => {
        handleChangeMasterBenefitInsurancePackages(selectedInsurances);
      },
      [handleChangeMasterBenefitInsurancePackages]
    );

    const adjustIsDefaultBeforeSet = useCallback(
      (selected: any) => {
        const hasAtLeastOneDefault = selected.find(
          (plan: any) => !!plan.isDefault
        );
        if (!hasAtLeastOneDefault) {
          const adjusted = selected.map((plan: any, ind: number) => ({
            ...plan,
            isDefault: ind === 0,
          }));
          setSelectedInsurancePackages(adjusted);
          changeMasterBenefitInsurancePackages(adjusted);
        } else {
          setSelectedInsurancePackages(selected);
          changeMasterBenefitInsurancePackages(selected);
        }
      },
      [changeMasterBenefitInsurancePackages]
    );

    const handleDelete = useCallback(
      (id: string) => {
        const filtered = selectedInsurancePackages.filter(
          (insurance: any) => insurance.id !== id
        );
        adjustIsDefaultBeforeSet(filtered);
        changeMasterBenefitInsurancePackages(filtered);
        Notification.notify(t(".deleteSuccess"));
      },
      [
        selectedInsurancePackages,
        adjustIsDefaultBeforeSet,
        changeMasterBenefitInsurancePackages,
        t,
      ]
    );

    const handleClickDeleteInsurance = useCallback(
      (props: any) => {
        const name = props.row.name;

        Modal.open({
          title: t(".deletePackageModalTitle"),
          children: (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".deletePackageModalInfo1")} &nbsp;
                </Typography>
                <Typography variant="body2" color={AppColor["Text/Black"]}>
                  {name} &nbsp;
                </Typography>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".deleteModalInfo2")}
                </Typography>
              </div>
            </>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".confirmDelete"),
          onOk: async ({ close }: any) => {
            handleDelete(props.id);
            close();
          },
        });
      },
      [t, handleDelete]
    );

    const handleSetDefault = useCallback(
      (id: any) => {
        const result = selectedInsurancePackages.map((packageItem: any) => {
          return {
            ...packageItem,
            isDefault: packageItem.id === id,
          };
        });

        adjustIsDefaultBeforeSet(result);
      },
      [adjustIsDefaultBeforeSet, selectedInsurancePackages]
    );

    const tableData = useMemo(() => {
      return (
        selectedInsurancePackages.map((data: any) => ({
          id: data.id,
          isDefault: data.isDefault,
          name: data.masterInsurancePackage.name,
          remark: data.masterInsurancePackage.remark,
          actions:
            !hasEditPermission || viewOnly
              ? []
              : [
                  {
                    Icon: RedTrashIcon,
                    onClick: handleClickDeleteInsurance,
                  },
                ],
        })) || []
      );
    }, [
      handleClickDeleteInsurance,
      viewOnly,
      selectedInsurancePackages,
      hasEditPermission,
    ]);

    const columns: GridColDef[] = useMemo(
      (): GridColDef[] => [
        {
          width: 250,
          field: "name",
          headerName: t(".packageName") || "",
        },
        {
          width: 250,
          field: "remark",
          headerName: t(".remark") || "",
        },
        {
          width: 90,
          field: "isDefault",
          headerName: t(".default") || "",
          filterable: false,
          sortable: false,
          renderCell: (params) => {
            return (
              <Radio
                checked={params.row.isDefault}
                icon={<UncheckRadioBoxIcon />}
                checkedIcon={<CheckedRadioBoxIcon />}
                disabled={!hasEditPermission || viewOnly}
                onClick={() => handleSetDefault(params.id)}
              />
            );
          },
        },
        {
          width: 70,
          field: "actions",
          headerName: t(".delete"),
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t, handleSetDefault, hasEditPermission, viewOnly]
    );

    const handleClickAddPackages = useCallback(async () => {
      setIsAddModalOpen(true);
    }, []);

    const handleConfirmSelection = useCallback(
      (values: any) => {
        adjustIsDefaultBeforeSet([...selectedInsurancePackages, ...values]);
        setIsAddModalOpen(false);
      },
      [selectedInsurancePackages, adjustIsDefaultBeforeSet]
    );

    const handleCloseAddModal = useCallback(() => {
      setIsAddModalOpen(false);
    }, []);

    const masterInsurancePackageOptions = useMemo(() => {
      const selectedIds = map(
        selectedInsurancePackages,
        "masterInsurancePackage.id"
      );
      return orderBy(
        masterInsurancePackages.filter(
          (plan: any) => !selectedIds.includes(plan.id)
        ),
        "createdAt",
        "asc"
      );
    }, [masterInsurancePackages, selectedInsurancePackages]);

    return {
      label: props.t(".packageHeader", { type: label }),
      isAddModalOpen,
      tableData,
      columns,
      viewOnly,
      masterInsurancePackageOptions,

      handleClickAddPackages,
      handleCloseAddModal,
      handleConfirmSelection,
    };
  })
);

export const InsurancePackageList = enhancer(InsurancePackageListComponent);
