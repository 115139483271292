export const AppColor = {
  "Background/Light Grey": "#F6F9FC",

  "Text/Dark Grey": "#767676",

  "Text/Black": "#212121",
  "Background/Dark Grey": "#E0E0E0",

  "Other/Success": "#4CAF4F",
  //
  "Background/Disable": "#C5C5C5",
  "Background/Background": "#EAEAEA",
  "Other Light/Warning": "#F8E6A4",
  "Other/Info": "#0084FF",
  "Other/Danger": "#FE7015",
  "Text/White": "#FFFFFF",
  "Text/Secondary": "#333333",
  "Text/Primary": "#000000",
  "Text/Line": "#7C7C7C",
  "Primary/Primary Text": "#EE2524",
  "Primary/Line": "#C80000",
  "Primary/Hover": "#C80000",
  "White / White": "#FFFFFF",
  "Primary/Primary": "#EE2524",
  "Secondary/Hover": "#C80000",
  "Secondary  Light/Background": "#FFE4E5",
};
